.backgroundPicture{
background-image: url("../assets//images//login-truck.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  z-index: -2;
}

.container{
    width: 40%; 
    height:100vh;
}

.form{
    height: 65vh;
    width: 100%;
    border: transparent 1px solid;
    border-radius: 20px;

    color: black;
    background: transparent;
    backdrop-filter: blur(100px) ;

}

@media (max-width: 900px) {
    .backgroundPicture{
      background-image: url("../assets//images//login-truck.png");
    }
    .container {
      width:60%;
    }
    .form{
        height: 75vh;
    }
  }

@media (max-width: 550px) {
    .container {
      width:90%;
    }
  }