.sidebar {
  width: 100%;
  max-width: 250px;
  background-color: var(--partitionColor) !important;
  border-right: var(--partitionColor) 1.5px solid;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
}

.sidebarMobile {
  background-color: var(--partitionColor) !important;
}

.open {
  transform: translateX(0);
}

.logo {
  margin-bottom: 1em;
  color: var(--extraColor);
  text-align: center;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

nav li {
  margin-bottom: 0.5rem;
}
nav a {
  display: block;
  padding: 0.5rem 1rem;
  color: var(--extraColor) !important;

  text-decoration: none;
  transition: all 100ms ease;
  justify-content: center;
}

nav a:hover {
  color: var(--primaryColor) !important;
}
.active {
  font-weight: 700;
  color: var(--primaryColor) !important;
  border-left: var(--primaryColor) 5px solid !important;
  border-radius: 3px;

  transition: color 600ms ease-in-out, border-left 300ms ease-in-out;
}

.logout {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(203, 5, 5) !important;
  text-decoration: none;
  transition: all 100ms ease;
  justify-content: center;
  cursor: pointer;
}
.logout:hover {
  color: rgb(255, 59, 59) !important;
}

.sandwichButton {
  margin: 5px 0 0 128px;
  z-index: 200;
  font-size: xx-large;
  color: var(--secondaryColor);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .sidebar {
    width: 250px; /* Adjust the width as needed */
    transform: none;
  }

  .sandwichButton {
    display: none;
  }
}
