body {
  margin: 0;
  padding: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

:root {
  --primaryColor: #207dff;
  --secondaryColor: black;
  --extraColor: white;
  --partitionColor: #083b82;
  --headingColor: #565656;
  --basicTextColor: rgb(110, 110, 110);

  /* --partitionColor: black; */
}
