.watermarkLogo {
  position: absolute;
  top: 5%;
  left: 15%;
  width: 100%;
  height: 100%;
  opacity: 0.07;
  pointer-events: none;
  background-image: url('../../../assets/images/logo.png');
  background-repeat: no-repeat;
  background-position: center;
}
