 .btn {
    background-color: transparent;
    color: var(--primaryColor) !important;
    border: 0px !important;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out; /* Define the transitions */
  }
  .btn:hover {
    transform: translateX(5px);
    background-color: var(--primaryColor) !important;
  }

  .btnWhite{
    color: white !important;
   }

  .dp {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
  }
  .dpContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
  }

  .primaryBtn{
    color:var(--primaryColor);
    border: var(--primaryColor) 1.5px solid !important;
  }
  .primaryBtn:hover{
    color:var(--extraColor)

  }