.table {
  width: 98%;
  max-width: 98%;
}

.headRow {
  border-bottom: var(--partitionColor) 1.5px solid;
}

.row {
  border-bottom: #d9dadb 1.5px solid;
  cursor: pointer;
}

.row:hover {
  border-radius: 20px;
  border-bottom: 0px;
  background-color: var(--partitionColor);
  color: var(--extraColor);
}

.column {
  border-bottom: 0px;
}

.content {
  color: var(--headingColor);
  font-size: medium;
  margin-bottom: 2px;
  text-align: justify;
  padding: 8px 0px;
  transition: transform 0.2s ease-in-out;
}
.content:hover {
  transform: translateX(5px);
}
.contentLight {
  color: var(--extraColor) !important;
  font-size: medium;
  margin-bottom: 2px;
  text-align: justify;
  padding: 8px 0px;
  transition: transform 0.2s ease-in-out;
}

.basicText {
  font-size: small;
  color: var(--basicTextColor);
}

.btn {
  background-color: transparent;
  color: var(--secondaryColor) !important;
  border: 0px !important;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out; /* Define the transitions */
}
.btn:hover {
  transform: translateX(5px);
  background-color: var(--secondaryColor) !important;
  color: black !important;
}

.tileImage {
  aspect-ratio: 16/9;
  max-width: 100px;
  max-height: 80px;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
}

.dp {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.dpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
